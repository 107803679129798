import { useSafeEnv } from './useSafeEnv';

export const useCanonicalUrl = () => {
  const route = useRoute();

  const { hostUrl } = useSafeEnv();

  const path = computed(() => (route.path === '/' ? route.path : route.path.replace(/\/$/, '')));

  useHead(() => ({
    link: [
      {
        rel: 'canonical',
        href: hostUrl.value + path.value,
      },
    ],
  }));
};
